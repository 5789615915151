import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Seo from "../utils/seo"
import BreakPoints from "../utils/screens"

import "../css/typography.css"
import "../css/colors.css"
import "../css/404.css"

import logo from "../images/logo_dark.svg"

const NotFoundPage = () => (
    <NotFoundWrapper>
      <Content>
        <Seo title="404: Not found" />
        <Logo>
          <a target="_blank" rel="noreferrer" href="https://demos.co.uk/">
            <img alt="Demos Logo" width="150" height="auto" src={logo} />
          </a>
        </Logo>
        <Title>
          404: Not Found
        </Title>
        <Description>
          Sorry, we were unable to find that page.
        </Description>
        <Link style={{ textDecoration: 'none' }} to="/">
          <BackButton className="back-home">
            Back to Article
          </BackButton>
        </Link>
      </Content>
    </NotFoundWrapper>
)

const NotFoundWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 40px;
`

const Content = styled.div`
  text-align: center;
`

const Title = styled.h1`
  font-family: "Black";
  font-size: var(--fs-l);
  color: var(--demos-dark);
`

const Description = styled.p`
  font-family: Roman;
  color: var(--demos-dark);
  ${BreakPoints.tn} {
    font-size: var(--fs-700);
  }
  ${BreakPoints.lg} {
    font-size: var(--fs-800);
  }
`

const BackButton = styled.div`
  font-family: Heavy;
  font-size: var(--fs-600);
  color: var(--demos-dark);
  text-transform: uppercase;
  border: 1px solid var(--demos-dark);
`

export default NotFoundPage
